// COMPONENTS
import Menu from '../../components/Menu/Menu'
import About from '../../components/About/About'
import Layout from '../../components/Layout/Layout'
import Work from '../../components/Work/Work'
import Education from '../../components/Education/Education'
import Skills from '../../components/Skills/Skills'
import Contact from '../../components/Contact/Contact'
import OtherEducation from '../../components/OtherEducation/OtherEducation'

// STYLES

const HomePage = (): JSX.Element => {
  return (
    <>
      <Menu></Menu>
      <Layout>
        <About />
        <Work />
        <Education />
        <OtherEducation />
        <Skills />
        <Contact />
      </Layout>
    </>
  )
}

export default HomePage
