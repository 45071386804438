import { Route, Switch, HashRouter as Router } from 'react-router-dom'
import Welcome from './components/Welcome/Welcome'
import HomePage from './pages/Home/home-page'
// import WorkPage from './pages/Work/work-page'
// import EducationPage from './pages/Education/education-page'
// import SkillsPage from './pages/Skills/skills-page'
// import ContactPage from './pages/Contact/contact-page'
import NotFound from './components/404/404'

// STYLES
import './App.scss'

const App = (): JSX.Element => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Welcome}></Route>
          <Route exact path="/about" component={HomePage}></Route>
          {/* <Route exact path="/work" component={WorkPage}></Route>
          <Route exact path="/education" component={EducationPage}></Route>
          <Route exact path="/skills" component={SkillsPage}></Route>
          <Route exact path="/contact" component={ContactPage}></Route> */}
          <Route component={NotFound}></Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
