import React, {useState, useEffect} from 'react';

//STYLES
import './Welcome.css';

const Welcome = ({history}) => {
    const [count, setCount] = useState(0);
    const firstPhrase = 'Esteban Arias';
    const [firstPhraseArr, setFirstPhraseArr] = useState([]);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const ac = new AbortController();
        if (count < firstPhrase.split('').length) {
            setTimeout(() => {
                    setCount(count + 1);
                    setFirstPhraseArr(firstPhraseArr.concat(firstPhrase.split('')[count]));
            }, 100);
        }else{
            setShowButton(true);
        }

        return () => {
            ac.abort();
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstPhraseArr])

    const enter = () => {
        document.querySelector('.phrase>h1').classList.add("fade-top");
        document.querySelector('.enter-button').classList.add("fade-down");
        
        setTimeout(() => {
            history.push('/about');
        }, 250);
    }

    return(
        <div className="welcome-container">
            <div id="areaFadeOut">
                <div className="phrase">
                    <h1>{
                        firstPhraseArr.map((element, index) => {
                            return <strong key={index}>{element}</strong>; 
                        })
                    }<strong className="slash">_</strong></h1>
                </div>
                <div className="enter-button">
                        <h4 className={showButton ? 'show-enter-button': ''}>Welcome to my page</h4>
                        <button onClick={enter} className={showButton ? 'show-enter-button': ''} type="button"><i className="fas fa-power-off"></i> Enter</button>
                </div>  
            </div>
        </div>
            
    )
}

export default Welcome;