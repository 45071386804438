import { motion, Variants } from 'framer-motion'

//STYLES
import styles from './Work.module.scss'

const Work = (): JSX.Element => {
  const cardVariants: Variants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8
      }
    }
  }

  return (
    <div id="work" className={styles.work}>
      <h1 data-aos="flip-up">Work Experience</h1>
      <motion.div initial="visible" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div variants={cardVariants}>
          <div data-aos="slide-left" className={styles.work__box}>
            <h2>Front End Developer</h2>
            <h4>PGD LATAM</h4>
            <h6>
              <i>Mar 2022 - Currently</i>
            </h6>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Collaborating on internal
              PGD projects.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Converting UX wireframes
              to actual code using HTML, SASS, React, and Jest for unit testing.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Participating in Agile
              ceremonies like stand ups, sprint planning retrospective, showcases, etc...
            </p>
          </div>
        </motion.div>
      </motion.div>

      <motion.div initial="visible" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div variants={cardVariants}>
          <div
            data-aos="slide-left"
            className={`${styles.work__box} ${styles['work__box--bg-black']}`}>
            <h2>Front End Developer</h2>
            <h4>IBM</h4>
            <h6>
              <i>Jan 2019 - Mar 2022</i>
            </h6>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Built custom, full
              responsive and appealing e-learning websites using HTML, CSS/SASS, handlebars,
              Javascript, JQuery and React for different IBM internal clients.
            </p>
            <p>
              {' '}
              <strong className={styles.work__box__bullet}>&gt;_</strong> Transformed UI wireframes
              provided by the UX design team to actual code.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Participated in the
              different Agile ceremonies, like daily stand ups, Sprint planning/retrospective,
              showcase, etc...
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Implemented responsive web
              design principles and applied best practices to ensure that our website solutions
              render well across in multiple devices.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Solved issues presented in
              different website templates that others UI developers use, creation of new widgets and
              enhance the existing ones.
            </p>
          </div>
        </motion.div>
      </motion.div>

      <motion.div initial="visible" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div variants={cardVariants}>
          <div data-aos="slide-left" className={styles.work__box}>
            <h2>Infotree Services - Intel</h2>
            <h4>Front End Developer</h4>
            <h6>
              <i>Jun 2018 - Oct 2018</i>
            </h6>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Intel contingent worker
              (Infotree services Inc. temporary employee).
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Coded new features to
              differents applications using C# and T-SQL following the Scrum methodology.
            </p>
          </div>
        </motion.div>
      </motion.div>

      <motion.div initial="visible" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
        <motion.div variants={cardVariants}>
          <div
            data-aos="slide-left"
            className={`${styles.work__box} ${styles['work__box--bg-black']}`}>
            <h2>Software Developer</h2>
            <h4>ASTA</h4>
            <h6>
              <i>Jul 2014 - Jun 2018</i>
            </h6>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Modified existing software
              to correct errors, and improve performance and usability.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Coded some aplications
              using .NET, vb.net, C#, LINQ, HTML, JQuery, KnockoutJS and Javascript.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Worked closely with intern
              users to establish problem specifications and system designs.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Maintained SQL Server
              databases.
            </p>
            <p>
              <strong className={styles.work__box__bullet}>&gt;_</strong> Developed and maintained
              the organization's web page - www.tamizajecr.com
            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Work
