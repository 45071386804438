import AnimatedText from '../AnimatedText/AnimatedText'
import { WebsiteCarbonBadge } from 'react-websitecarbon-badge'
import styles from './Contact.module.scss'

const Contact = (): JSX.Element => {
  return (
    <div id="contact" className={styles.contact_container}>
      <h1 data-aos="flip-up">Contact Me</h1>
      <div data-aos="fade-right" className={styles.contact_container__information}>
        <p>If you want to know more about me, my skills and my experience, send me an email to: </p>
        <AnimatedText textType="text" speed={100}>
          tegoarias@hotmail.com
        </AnimatedText>
        <p>"Success is not what you have, but who you are"</p>
        <WebsiteCarbonBadge co2="0.06" percentage="94" dark={true} url="www.eariasc.com" />
      </div>
    </div>
  )
}

export default Contact
