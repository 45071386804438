//COMPONENTS
import Skill from '../Skill/Skill'

//STYLES
import styles from './Skills.module.scss'

const Skills = (): JSX.Element => {
  const mySkills = [
    {
      skill: 'Javascript',
      level: 90
    },
    {
      skill: 'HTML/CSS',
      level: 90
    },
    {
      skill: 'JQuery',
      level: 85
    },
    {
      skill: 'Angular',
      level: 50
    },
    {
      skill: 'React',
      level: 80
    },
    {
      skill: 'NodeJS',
      level: 65
    }
  ]

  return (
    <div id="skills" className={styles.skills}>
      <h1 data-aos="flip-up" data-aos-delay="200">
        Skills{' '}
      </h1>
      <div className={styles.skills__container}>
        {mySkills
          ? mySkills.map((el, idx) => (
              <Skill
                key={idx}
                skillname={el.skill}
                level={el.level}
                observerSelector={styles.skills__container}></Skill>
            ))
          : null}
      </div>
    </div>
  )
}

export default Skills
