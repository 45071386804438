import * as React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
// import { Link } from 'react-router-dom'
import styles from './Menu.module.scss'

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [activeSection, setActiveSection] = useState('')

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const goToSection = (section) => {
    setActiveSection(section)
    const sectionEl = document.getElementById(section)
    const menuMobile = document.querySelector('#menu_mobile')
    if (menuMobile) {
      menuMobile.classList.remove(styles.menu__mobile__show)
      menuMobile.classList.add(styles.menu__mobile__hidden)
    }
    window.scroll({
      top: sectionEl.offsetTop - 100,
      behavior: 'smooth'
    })
  }

  return (
    <Fragment>
      <nav className={styles.menu__desktop}>
        <ul>
          <li
            className={activeSection === 'about' ? styles.active : undefined}
            onClick={() => goToSection('about')}>
            About
          </li>

          <li
            className={activeSection === 'work' ? styles.active : undefined}
            onClick={() => goToSection('work')}>
            Work
          </li>

          <li
            className={activeSection === 'education' ? styles.active : undefined}
            onClick={() => goToSection('education')}>
            Education
          </li>

          <li
            className={activeSection === 'otherEducation' ? styles.active : undefined}
            onClick={() => goToSection('otherEducation')}>
            Other Education
          </li>

          <li
            className={activeSection === 'skills' ? styles.active : undefined}
            onClick={() => goToSection('skills')}>
            Skills
          </li>

          <li
            className={activeSection === 'contact' ? styles.active : undefined}
            onClick={() => goToSection('contact')}>
            Contact
          </li>
        </ul>
      </nav>
      <nav
        id="menu_mobile"
        className={`${styles.menu__mobile} ${
          showMenu ? styles.menu__mobile__show : styles.menu__mobile__hidden
        }`}>
        <button className={styles.menu__mobile__button} onClick={toggleMenu}></button>
        <ul>
          <li
            className={activeSection === 'about' ? styles.active : undefined}
            onClick={() => goToSection('about')}>
            About
          </li>

          <li
            className={activeSection === 'work' ? styles.active : undefined}
            onClick={() => goToSection('work')}>
            Work
          </li>

          <li
            className={activeSection === 'education' ? styles.active : undefined}
            onClick={() => goToSection('education')}>
            Education
          </li>

          <li
            className={activeSection === 'otherEducation' ? styles.active : undefined}
            onClick={() => goToSection('otherEducation')}>
            Other Education
          </li>

          <li
            className={activeSection === 'skills' ? styles.active : undefined}
            onClick={() => goToSection('skills')}>
            Skills
          </li>

          <li
            className={activeSection === 'contact' ? styles.active : undefined}
            onClick={() => goToSection('contact')}>
            Contact
          </li>
        </ul>
      </nav>
    </Fragment>
  )
}

export default Menu
