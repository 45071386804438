//STYLES
import styles from './OtherEducation.module.scss'

import Particles from 'react-particles-js'
import particlesObj from '../../utils/particles-obj'

const OtherEducation = (): JSX.Element => {
  return (
    <div id="otherEducation" className={styles.other_education__container}>
      <Particles className="particles-js" params={particlesObj}></Particles>
      <h1>Other Education</h1>

      <div className={styles.other_education__box__container}>
        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>Git Essential Training: The Basics</h5>
          <p>
            <strong>2022</strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.linkedin.com/learning/certificates/c1450bd9adc401b7ca81453e04daf66f397fe52311d37a3349e86899cb99ca40?u=2130370"
                target="_blank"
                rel="noreferrer">
                Linkedin Learning
              </a>
            </strong>
          </p>
        </div>

        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>Sass Essential Training</h5>
          <p>
            <strong>2020</strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.linkedin.com/learning/certificates/3d8eedbbc3ce1872730f6ccf62d9119123318ab404b8133468e56185f13b78ba?u=2130370"
                target="_blank"
                rel="noreferrer">
                Linkedin Learning
              </a>
            </strong>
          </p>
        </div>

        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>React Front to Back</h5>
          <p>
            <strong>2020</strong>
          </p>
          <p>
            <strong>
              <a
                href="http://use.my/UC-402b62fb-cc8a-473e-9347-86556c82a77b"
                target="_blank"
                rel="noreferrer">
                Udemy
              </a>
            </strong>
          </p>
        </div>

        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>The complete NodeJS Developer Course</h5>
          <p>
            <strong>2020</strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.udemy.com/certificate/UC-A20L2PXE/"
                target="_blank"
                rel="noreferrer">
                Udemy
              </a>
            </strong>
          </p>
        </div>

        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>Javascript understanding the weird parts</h5>
          <p>
            <strong>2019</strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.udemy.com/certificate/UC-OXVDD9ZN/"
                target="_blank"
                rel="noreferrer">
                Udemy
              </a>
            </strong>
          </p>
        </div>

        <div data-aos="fade-up" className={styles.other_education__box}>
          <h5>IBM Agile Explorer</h5>
          <p>
            <strong>2019</strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.credly.com/badges/9475c105-c091-4def-910b-923782925b49/linked_in_profile"
                target="_blank"
                rel="noreferrer">
                IBM
              </a>
            </strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export default OtherEducation
