//STYLES
import styles from './Education.module.scss'

const Education = (): JSX.Element => {
  return (
    <div id="education" className={styles.education_container}>
      <h1 data-aos="fade-down">Education</h1>

      <div className={styles.education_box_container}>
        <div className={styles.education_box}>
          <h3 className={styles.education_box__title} data-aos="fade-left">
            Bach. Software Engineering
          </h3>
          <div className={styles.education_box__description}>
            <p className="text-md" data-aos="fade-left">
              <strong>Universidad Central</strong>
            </p>
            <p className="text-bg" data-aos="flip-up">
              <strong>2015</strong>
            </p>
          </div>
        </div>

        <div className={styles.education_box}>
          <h3 className={styles.education_box__title} data-aos="fade-left">
            Front End Web Developer
          </h3>
          <div className={styles.education_box__description}>
            <p className="text-md" data-aos="fade-left">
              <strong>Universidad Cenfotec</strong>
            </p>
            <p className="text-bg" data-aos="flip-up">
              <strong>2019</strong>
            </p>
          </div>
        </div>

        <div className={styles.education_box}>
          <h3 className={styles.education_box__title} data-aos="fade-left">
            Fullstack Web Developer
          </h3>
          <div className={styles.education_box__description}>
            <p className="text-md" data-aos="fade-left">
              <strong>Texas Tech University Costa Rica</strong>
            </p>
            <p className="text-bg" data-aos="flip-up">
              <strong>2021</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Education
