import { HTMLAttributes, useEffect, useState } from 'react'

type Props = {
  children: string
  speed: number
  textType: 'text' | 'title'
}

const AnimatedText = ({
  children,
  textType,
  speed,
  ...rest
}: Props & HTMLAttributes<HTMLElement>): JSX.Element => {
  const [count, setCount] = useState(0)
  const [textArr, setTextArr] = useState([])

  useEffect(() => {
    const ac = new AbortController()

    if (count <= children.split('').length) {
      setTimeout(() => {
        setCount(count + 1)
        setTextArr(textArr.concat(children.split('')[count]))
      }, speed)
    }

    return () => {
      ac.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textArr])

  return textType === 'title' ? (
    <h1 {...rest}>
      {textArr.map((element, index) => (
        <strong key={index}>{element}</strong>
      ))}
    </h1>
  ) : textType === 'text' ? (
    <p {...rest}>
      {textArr.map((element, index) => (
        <strong key={index}>{element}</strong>
      ))}
    </p>
  ) : null
}
export default AnimatedText
