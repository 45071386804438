import { useState, useEffect, useCallback } from 'react'
import styles from './Skill.module.scss'

type Props = {
  skillname: string
  level: number
  observerSelector: string
}

const Skill = ({ skillname, level, observerSelector }: Props): JSX.Element => {
  const [levelSkill, setLevelSkill] = useState(0)

  const test = useCallback(() => {
    let i = 0
    const interval = setInterval(() => {
      if (i <= level) {
        setLevelSkill(i)
      } else {
        clearInterval(interval)
      }
      i++
    }, 10)
  }, [level])

  const callback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          test()
        }
      })
    },
    [test]
  )

  useEffect(() => {
    const skillsContainerEl = document.getElementsByClassName(observerSelector)[0]
    const observer = new IntersectionObserver(callback)
    observer.observe(skillsContainerEl)
  }, [observerSelector, callback])

  return (
    <div className={styles.skill}>
      <div className={styles.skill__name}>
        <h3>{skillname}</h3>
      </div>
      <div className={styles.skill__bar__container}>
        <div className={styles.skill__bar} style={{ width: levelSkill + '%' }}></div>
      </div>
      <h4 className={styles.skill__percentage}>{levelSkill}%</h4>
    </div>
  )
}

export default Skill
