// STYLES
import styles from './About.module.scss'
import profilePicture from '../../assets/img/esteban.jpg'

const About = (): JSX.Element => {
  return (
    <div id="about" data-aos="fade-up" data-aos-delay="100" className={styles.about__me}>
      <img src={profilePicture} alt="Esteban Arias" className={styles.about__me__picture} />
      <div className={styles.about__me__intro}>
        <h1 data-aos="fade-right" data-aos-delay="500">
          About Me
        </h1>
        <p>
          Hello, my name is Esteban Arias, and I'm a software engineer with more than 4 years of
          experience working as a front-end engineer. With that experience, I have developed a
          variety of websites using technologies such as JavaScript, HTML, CSS, SASS, React,
          KnockoutJS, and NodeJS.
        </p>
        <p>
          I am confident in my ability to develop responsive, intuitive, and engaging user
          interfaces and experiences. I have a strong eye for detail and a passion for creating
          visually appealing designs. My experience also includes debugging, testing, and
          problem-solving, and I am comfortable working with different teams to ensure the
          successful completion of projects.
        </p>

        <div className={styles.about__me__intro__social}>
          <i className={`fab fa-linkedin ${styles.linkedin}`}></i>
          <a
            href="https://cr.linkedin.com/in/esteban-arias-1b9b2094"
            target="_blank"
            rel="noopener noreferrer">
            eariasc
          </a>
          <i className={`fas fa-envelope ${styles.email}`}></i>
          <a href="\\hotmail.com" target="_blank" rel="noopener noreferrer">
            tegoarias@hotmail.com
          </a>
        </div>
      </div>
    </div>
  )
}

export default About
