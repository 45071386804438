import React from 'react';

const  NotFound =() => {

        return(
            <div className="desert-bg not-found">
                <h1 className="p-5 text-white">404 NOT FOUND!</h1>
            </div>
        )
}

export default NotFound;